import { IEndpoint, IKibanaConfig } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-trn2.uap.jpnmob-acyan.prd.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '899ec040-2872-11ef-a88b-d5109b6f70a2',
  kibanaGQLIndex: '9c0a05a0-2872-11ef-bb84-7f9b2b84c86a',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}
