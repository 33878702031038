import { IConstants, IEndpoint, IActiveConfig, IKibanaConfig } from '../types'

const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: 'uap-acyan-api-lt',
  kibanaGQLIndex: 'uap-acyan-cluster-lt',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-lt-au.uap.jpnmob-acyan.npr.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '1ea853d0-2711-11ef-91d6-698f0d392d95',
  kibanaGQLIndex: '29cddfa0-2711-11ef-8be9-29cebe195446',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  constants: defaultConstants,
  authConfig: {
    domainName: 'asurion-uap-acyan-uat-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '4ivufqh7qs4p27bacmlkufpucn',
    userPoolId: 'ap-northeast-1_zaaivQPhB',
    identityPoolId: '',
    identityProvider: 'acyan-uat-SSO',
  },
  lt: kibanaConfig,
  endpoints: [
    {
      key: 'lt',
      value: defaultEndpointConfig,
    },
  ],
}
