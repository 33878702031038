import { IActiveConfig, IConstants, IEndpoint, IKibanaConfig } from '../types'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: '51173110-286f-11ef-90b9-dba91ae15c0d',
  kibanaGQLIndex: '5e8c6288-821e-4b7e-8832-105034bace5b',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan.ucp.jpnmob-acyan.prd.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '51173110-286f-11ef-90b9-dba91ae15c0d',
  kibanaGQLIndex: '46d32ba0-286f-11ef-9a2d-25fdba9dde78',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-prd-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '2mroo3ukv5mdjip2h87p27l0l8',
    userPoolId: 'ap-northeast-1_btTUUBO3S',
    identityPoolId: '',
    identityProvider: 'acyan-prd-SSO',
  },
  constants: defaultConstants,
  prd: kibanaConfig,
  endpoints: [
    {
      key: 'prd',
      value: defaultEndpointConfig,
    },
  ],
}
