import { IEndpoint, IKibanaConfig } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-trn1.uap.jpnmob-acyan.prd.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '817f6f90-2872-11ef-bb84-7f9b2b84c86a',
  kibanaGQLIndex: '922b78c0-2872-11ef-bb84-7f9b2b84c86a',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}
